//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import FiltersList from './Components/FiltersList';
import FilterPopup from './Components/FilterPopup';
import arrow from '~/assets/img/icons/settings.svg?raw';
import FilterApplied from './Components/FilterApplied';
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: 'StoreFilterMinimal',
    components: { FilterApplied, FiltersList, FilterPopup },
    mixins: [
        breakpointsListMixin,
    ],
    data() {
        return {
            arrowIcon: arrow,
        };
    },
    computed: {
        ...mapGetters({
            filters: 'catalog/getFiltersList',
            productsCount: 'catalogStore/productsCount',
            showClearAllLink: 'catalogStore/showClearAllLink',
            clearLinkUrl: 'catalogStore/clearLinkUrl',
            getMobileFiltersPopupShowed: 'catalogStore/getMobileFiltersPopupShowed',
        }),
        closeLink() {
            return '/man';
        },
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else {
                return false;
            }
        }
    },
    watch: {
        getMobileFiltersPopupShowed: {
            handler(popupState) {
                if (popupState) {
                    this.onOpenPopup();
                } else {
                    this.onClosePopup();
                }
            },
            immediate: true,
        }
    },
    methods: {
        openPopup(){
            this.changePopupState(true);
        },
        closePopup() {
            this.changePopupState(false);
        },
        changePopupState(state){
            this.$store.commit(`catalogStore/${ types.CHANGE_MOBILE_FILTERS_POPUP_SHOWED_STATE }`, state);
        },
        onClosePopup() {
            this.body && this.body.classList.remove('v-popup-open');
            this.$liveChat && this.$liveChat.show();
        },
        onOpenPopup() {
            this.body && this.body.classList.add('v-popup-open');
            this.isMacOs && this.body.classList.add('isMacOs');
            this.$liveChat && this.$liveChat.hide();
        },
    },
};
