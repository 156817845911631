//
//
//
//
//
//
//
//
//
//
//

import FiltersListItem from "./Components/FiltersListItem";
import {mapGetters} from "vuex";
import FiltersSort
    from "./Components/FiltersSort";
export default {
    name: "FiltersList",
    props: {
        showMore: {
            type: Boolean,
            default: false
        }
    },
    components: {FiltersSort, FiltersListItem},
    computed: {
        ...mapGetters({
            filters:  'catalogStore/getFilters',
        }),
    }
}
